* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

#root {
  display: flex;
  flex-direction: row;
  height: 100svh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiBox-root::-webkit-scrollbar {
  display: none;
}

div.editable {
  max-height: 14rem;
}

/* h1 {
  margin-top: 48vh;
  margin-left: 38%;
  color: #1976d2;
} */

.MuiMenu-paper {
  border-radius: 12px !important;
  width: 25%;
  padding: 0.4%;
}

textarea {
  resize: none;
}

textarea::-webkit-scrollbar {
  display: none;
}

@media screen and (max-width: 1023px) and (min-width: 0px) {
  .MuiMenu-paper {
    border-radius: 12px !important;
    width: 80%;
    padding: 0.2%;
  }
}
